import React from 'react'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { usePriceCakeBusd } from 'state/farms/hooks'
import { useProfile } from 'state/profile/hooks'
import styled from "styled-components";
import { Text, TwitterIcon, TelegramIcon, BookIcon, DocsIcon } from '@pancakeswap/uikit'

const Container = styled.div`
  background: #081321;
  position: relative;
  padding: 70px 50px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 30px 50px;
  }
`;

const BottomMenuLinks = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;  

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 35%;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SocialGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  ${({ theme }) => theme.mediaQueries.lg} {
    width: 60%;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const LogoDescription = styled.div`
  display: flex;
  gap: 10px;
`;

const PoweredBy = styled.div`
  display: flex;
  justify-content: center;
`;

const PoweredByImage = styled.img`
  width: 200px;
  object-fit: contain;
`;

const LogoImage = styled.img`
  width: 100px;
  object-fit: contain;
`;

const BombImage = styled.img`
  position: absolute;
  bottom: 100px;
  right: 0;
  width: 150px;
  object-fit: contain;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 300px;
  }
`;

const ChartsGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: column;
    justify-content: start;
  }
`;

const BaseBridge = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BottomMark = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BottomMarkImage = styled.img`
  width: 500px;
  object-fit: contain;
`;

const BottomMenu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const { profile } = useProfile()
  const { currentLanguage, setLanguage, t } = useTranslation()

  return (
    <Container>
      <BaseBridge>
        <Text bold fontSize="16px" color="menuText">Bridge to Base</Text>
      </BaseBridge>
      <BottomMark>
        <BottomMarkImage src="/images/bottom_mark.png" alt="bomb_bottom" />
      </BottomMark>
      <BombImage src="/images/BOMB.png" alt="BOMB" />
      <BottomMenuLinks>
        <SocialGroup>
          <Socials>
            <a href="https://twitter.com/BaseBOMB_"><TwitterIcon width="30px" /> </a>
            <a href="https://t.me/BaseBomber"><TelegramIcon width="30px" /> </a>
            <a href="http://basebomb.rcom.by/"><BookIcon width="30px" /> </a>
            <a href="https://basebomb.gitbook.io/"><DocsIcon width="30px" /> </a>
          </Socials>
          <LogoDescription>
            <LogoImage src="/images/bomb_logo.png" alt="bomblogo" />
            <Text bold width="100%" fontSize="12px" color="textSubtle">runs on the fastest Layer 1 blockchain Base Chain.</Text>
          </LogoDescription>
          <PoweredBy>
            <PoweredByImage src="/images/poweredby.png" alt="poweredby" />
          </PoweredBy>
        </SocialGroup>
        {/* <ChartsGroup>
          <Text bold fontSize="16px" color="menuText">Charts</Text>
          <Text fontSize="14px" color="textSubtle">Geckoterminal</Text>
          <Text fontSize="14px" color="textSubtle">Dex Screnner</Text>
        </ChartsGroup> */}
      </BottomMenuLinks>
    </Container>
  )
}

export default BottomMenu
